import '../stylesheet/main.scss'

import App from 'next/app'
import ErrorPage from 'next/error'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { getGlobalData } from 'utils/api'
import { getStrapiMedia } from 'utils/media'

import Layout from '@/components/layout/layout'

const MyApp = ({ Component, pageProps }) => {
  // Prevent Next bug when it tries to render the [[...slug]] route
  const router = useRouter()
  if (router.asPath === '/[[...slug]]' || router.isFallback) {
    return null
  }

  // Extract the data we need
  const { global } = pageProps
  if (global == null) {
    return <ErrorPage statusCode={404} />
  }

  const { metadata, favicon, metaTitleSuffix } = global

  return (
    <>
      {/* Favicon */}
      <Head>
        <link rel="shortcut icon" href={getStrapiMedia(favicon.data.attributes.url)} />
        <meta name="viewport" content="width=device-width, viewport-fit=cover" />
      </Head>
      <DefaultSeo
        titleTemplate={`%s | ${metaTitleSuffix}`}
        title={'Blog'}
        description={metadata.metaDescription}
        openGraph={{
          images: Object.values(metadata.shareImage.data.attributes.formats).map(
            image => {
              return {
                url: getStrapiMedia(image.url),
                width: image.width,
                height: image.height
              }
            }
          )
        }}
        twitter={{
          cardType: metadata.twitterCardType,
          handle: metadata.twitterUsername
        }}
      />
      {/* Display the content */}
      <Layout global={global}>
        <Component {...pageProps} />
      </Layout>
    </>
  )
}

MyApp.getInitialProps = async context => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProperties = await App.getInitialProps(context)
  // Fetch global site settings from Strapi
  const global = await getGlobalData()
  const UA = context.ctx.req.headers['user-agent']
  const isMobile = Boolean(
    /android|blackberry|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/i.test(UA)
  )
  // Pass the data to our page via props
  return {
    ...appProperties,
    pageProps: {
      global: global.data.attributes,
      path: context.pathname,
      isMobileType: isMobile
    }
  }
}

export default MyApp
