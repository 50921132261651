import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'

import CustomImage from './images/image'
import classNames from 'classnames'

const NavbarLink = ({
  section,
  onMouseEnter = () => {},
  changeActive,
  onClick = () => {},
  className
}) => {
  const { name, url, icon } = section
  const router = useRouter()
  const linkRoute = `/${name.toLowerCase()}`
  const ref = useRef()
  const isActive = useMemo(
    () =>
      router.asPath === '/'
        ? '/home' === linkRoute
        : router.asPath.split('?')[0] === linkRoute,
    [router, linkRoute]
  )

  const handleMouseEnter = () => {
    onMouseEnter(ref)
  }

  useEffect(() => {
    if (isActive && ref && changeActive) changeActive(ref)
  }, [router])

  const classes = classNames('navbar-link-text', { 'active-link': isActive }, className)

  return (
    <Link href={url}>
      <div
        className={classes}
        onMouseEnter={handleMouseEnter}
        ref={ref}
        role="button"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick()
          }
        }}
        onClick={onClick}
      >
        {icon && (
          <div className="navbar-link-icon">
            <CustomImage media={icon.data.attributes} alt={name} priority layout="fill" />
          </div>
        )}
        <a role="presentation">{name}</a>
      </div>
    </Link>
  )
}

export default NavbarLink
