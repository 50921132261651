import Image from 'next/image'
import { HtmlToBase64 } from 'utils/base64'
import { getStrapiMedia } from 'utils/media'

const Shimmer = (w = '100%', h = '100%') => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#D4D4D440" offset="0%" />
      <stop stop-color="#FFFFFF40" offset="100%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#D4D4D440" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

const CustomImage = ({
  media,
  className = '',
  layout,
  width,
  height,
  objectFit,
  objectPosition,
  sizes,
  priority = false,
  src,
  alt,
  shimmer = false
}) => {
  const altText = alt || media?.alternativeText || ''
  const fullUrl = src || getStrapiMedia(media.url)

  return (
    <Image
      src={fullUrl}
      alt={altText}
      width={width}
      height={height}
      layout={layout}
      className={className}
      objectFit={objectFit}
      objectPosition={objectPosition}
      sizes={sizes}
      priority={priority}
      placeholder={shimmer ? 'blur' : 'empty'}
      blurDataURL={`data:image/svg+xml;base64,${HtmlToBase64(Shimmer(width, height))}`}
    />
  )
}

export default CustomImage
