import {
  createGetGlobalEndpoints,
  createGetJobOfferEndpoint,
  createGetPagesEndpoint,
  createGetProjectEndpoint,
  createGetSeminarEndpoint,
  getStrapiURL
} from 'utils/endpoints'

// Helper to make GET requests to Strapi

export async function fetchAPI(path, options = {}) {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options
  }
  const requestUrl = getStrapiURL(path)
  const response = await fetch(requestUrl, mergedOptions)

  if (!response.ok) {
    throw new Error(`An error occured please try again`)
  }

  return await response.json()
}

// Get site data from Strapi (metadata, navbar, footer...)
export async function getGlobalData() {
  return await fetchAPI(createGetGlobalEndpoints({ populate: 'deep' }))
}

export async function getJobOfferData(id) {
  try {
    const jobOfferData = await fetchAPI(createGetJobOfferEndpoint(id))

    // Make sure we found something, otherwise return null
    if (jobOfferData == undefined || jobOfferData.length === 0) {
      return null
    }

    return jobOfferData
  } catch {
    return null
  }
}

export async function getPageData(slug, preview = false) {
  // Find the pages that match this slug
  const pagesData = await fetchAPI(
    createGetPagesEndpoint({ slug: slug, preview: preview })
  )

  // Make sure we found something, otherwise return null
  if (pagesData == undefined || pagesData.data.length === 0) {
    return null
  }

  // Return the first item since there should only be one result per slug
  return pagesData
}

export async function getProjectData(id) {
  try {
    const projectData = await fetchAPI(createGetProjectEndpoint(id))

    if (projectData == undefined || projectData.length === 0) {
      return null
    }

    return projectData
  } catch {
    return null
  }
}

export async function getSeminarData(id) {
  try {
    const seminarsData = await fetchAPI(createGetSeminarEndpoint({ id }))

    if (seminarsData == undefined || seminarsData.data.length === 0) {
      return null
    }

    return seminarsData
  } catch {
    return null
  }
}

export async function getTranscriptionContent(transcriptionUrl) {
  try {
    const res = await fetch(transcriptionUrl)
    if (!res.ok) {
      throw new Error(`An error occured please try again`)
    }
    return res.text()
  } catch {
    return null
  }
}
