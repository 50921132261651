import { Suspense } from 'react'

import Footer from './footer'
import Navbar from './navbar'
import ScrollToTopButton from './scrollToTopButton'

const Layout = ({ global, children }) => (
  <Suspense
    fallback={
      <div>
        <p>Loading</p>
      </div>
    }
  >
    <Navbar navbar={global.navbar} isMobileType={children.props.isMobileType} />
    <div className="layout-wrapper">{children}</div>
    <ScrollToTopButton />
    <Footer footer={global.footer} isMobileType={children.props.isMobileType} />
  </Suspense>
)

export default Layout
