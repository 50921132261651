import { CaretUpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useEffect, useState } from 'react'

const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const toggleVisible = () => {
      setVisible(window.scrollY > 650)
    }

    window.addEventListener('scroll', toggleVisible)

    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  })

  return (
    <div
      className="scroll-to-top-button-container"
      style={{ bottom: visible ? '25px' : '-100px' }}
    >
      <Button
        shape="circle"
        onClick={scrollToTop}
        className="scroll-to-top-button"
        icon={<CaretUpOutlined />}
      />
    </div>
  )
}

export default ScrollToTopButton
