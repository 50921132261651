import qs from 'qs'

const STRAPI_URL = process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337'

const CDN_SEMINARS_URL = process.env.NEXT_PUBLIC_SEMINARS_VIDEOS_URL

const getStrapiURL = path => `${STRAPI_URL}/api${path}`

const deepValue = 'deep,3'

const getPopulateString = populate => (populate ? `&populate=${populate}` : '')

const createGetApplyFormSubmissions = () => {
  return '/apply-form-submissions'
}

const createGetArticlesEndpoint = articleId => {
  return `articles/${articleId}`
}

const createGetCategoriesEndpoint = ({ limit = 100, populate = deepValue }) => {
  return `/categories?_limit=${limit}${getPopulateString(populate)}`
}

const createGetIndustriesEndpoint = ({ limit = 100, populate = deepValue }) => {
  return `/industries?_limit=${limit}${getPopulateString(populate)}`
}

const createGetContactFormSubmissionEndpoint = () => {
  return '/contact-form-submissions'
}

const createGetDataEndpoint = ({
  endpoint,
  limit = 4,
  page = 1,
  sortBy = 'publish_date',
  ascending = false,
  start = null,
  populate = deepValue
}) => {
  const order = ascending ? 'asc' : 'desc'
  const startFetching = start ? `_start=${start}` : ''

  return `/${endpoint}?pagination[page]=${page}&pagination[pageSize]=${limit}&sort=${sortBy}${startFetching}:${order}${getPopulateString(
    populate
  )}`
}

const createGetGlobalEndpoints = ({ populate = 'deep' }) => {
  return `/global?populate=${populate}`
}

const createGetIndividualsEndpoint = ({
  limit = 100,
  page = 1,
  populate = deepValue,
  filters
}) => {
  let query = ''
  if (filters) {
    const queryName = filters.name ? containsQuery('name', filters.name) : ''
    const queryPosition =
      filters.position && filters.position.length > 0
        ? relationsQuery('position', filters.position)
        : ''
    const queryIsEmployee = filters.isEmployee
      ? equalsQuery('is_employee', filters.isEmployee)
      : ''

    query = `${queryName}${queryPosition}${queryIsEmployee}`
  }
  return `/individuals?pagination[page]=${page}&pagination[pageSize]=${limit}${getPopulateString(
    populate
  )}${query}`
}

const createGetJobOfferEndpoint = id => {
  return `/job-offers/${id}?populate=${deepValue}`
}

const createGetJoinOurWaitListSubmission = () => {
  return '/join-our-waitlist-submissions'
}

const createGetPagesEndpoint = ({
  slug = '',
  status = 'published',
  preview = false,
  populate = 'deep'
}) => {
  return `/pages?filters[slug][$eq]=${slug}&status=${status}${
    preview ? '&status=draft' : ''
  }&populate=${populate}`
}

const createGetProjectEndpoint = id => {
  return `/projects/${id}?populate=deep,2`
}

const createGetProjectsEndpoint = ({
  limit = 4,
  page = 1,
  sortBy = 'start_date',
  ascending = false,
  start = null,
  populate = deepValue,
  filters
}) => {
  const order = ascending ? 'asc' : 'desc'
  const startFetching = start ? `_start=${start}` : ''
  let query = ''
  if (filters) {
    const queryTitle = filters.name
      ? multipleContainsQuery(['name', 'description'], filters.name)
      : ''
    const queryCategories =
      filters.category && filters.category.length > 0
        ? relationsQuery('categories', filters.category)
        : ''
    const queryTechnologies =
      filters.technology && filters.technology.length > 0
        ? relationsQuery('technologies', filters.technology)
        : ''
    const queryIndividuals =
      filters.individual && filters.individual.length > 0
        ? relationsQuery('individuals', filters.individual)
        : ''
    const queryIndustries =
      filters.industry && filters.industry.length > 0
        ? relationsQuery('industries', filters.industry)
        : ''
    query = `${queryTitle}${queryCategories}${queryTechnologies}${queryIndividuals}${queryIndustries}`
  }

  return `/projects?pagination[page]=${page}&pagination[pageSize]=${limit}&sort=${sortBy}${startFetching}:${order}${getPopulateString(
    populate
  )}${query}`
}

const createGetSeminarEndpoint = ({ id, status = 'published', populate = deepValue }) => {
  return `/seminars/${id}?status=${status}&populate=${populate}`
}

const createGetSeminarsEndpoint = ({
  limit = 4,
  page = 1,
  sortBy = 'publish_date',
  ascending = false,
  start = null,
  populate = deepValue,
  filters
}) => {
  const order = ascending ? 'asc' : 'desc'
  const startFetching = start ? `_start=${start}` : ''
  let query = ''
  if (filters) {
    const queryTitle = filters.title
      ? multipleContainsQuery(['title', 'description'], filters.title)
      : ''
    const queryCategories =
      filters.category && filters.category.length > 0
        ? relationsQuery('categories', filters.category)
        : ''
    const queryTechnologies =
      filters.technology && filters.technology.length > 0
        ? relationsQuery('technologies', filters.technology)
        : ''
    const queryIndividual =
      filters.individual && filters.individual.length > 0
        ? relationsQuery('individuals', filters.individual)
        : ''

    query = `${queryTitle}${queryCategories}${queryTechnologies}${queryIndividual}`
  }

  return `/seminars?pagination[page]=${page}&pagination[pageSize]=${limit}&sort=${sortBy}${startFetching}:${order}${getPopulateString(
    populate
  )}${query}`
}

const createGetSurveySubmissionEndpoint = () => {
  return '/survey-submissions'
}

const createGetTechnologiesEndpoint = ({ limit = 100, populate = deepValue }) => {
  return `/technologies?_limit=${limit}${getPopulateString(populate)}`
}

const createGetSeminarsVideoEndpoint = (video) => `${CDN_SEMINARS_URL}/${video}`

const createGetSeminarsTranscriptionsEndpoint = (transcription) => `${CDN_SEMINARS_URL}/${transcription}`

export {
  STRAPI_URL,
  CDN_SEMINARS_URL,
  getStrapiURL,
  createGetApplyFormSubmissions,
  createGetArticlesEndpoint,
  createGetCategoriesEndpoint,
  createGetIndustriesEndpoint,
  createGetContactFormSubmissionEndpoint,
  createGetDataEndpoint,
  createGetGlobalEndpoints,
  createGetIndividualsEndpoint,
  createGetJobOfferEndpoint,
  createGetJoinOurWaitListSubmission,
  createGetPagesEndpoint,
  createGetProjectEndpoint,
  createGetProjectsEndpoint,
  createGetSeminarsEndpoint,
  createGetSeminarEndpoint,
  createGetSurveySubmissionEndpoint,
  createGetTechnologiesEndpoint,
  createGetSeminarsVideoEndpoint,
  createGetSeminarsTranscriptionsEndpoint
}

const multipleContainsQuery = (keys, value) =>
  keys.map((key, index) => `&filters[$or][${index}][${key}][$contains]=${value}`).join('')

const containsQuery = (key, value) =>
  `&${qs.stringify(
    {
      filters: {
        [key]: {
          $contains: value
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )}`

const relationsQuery = (key, value) =>
  `&${qs.stringify(
    {
      filters: {
        [key]: {
          id: { $eq: value }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )}`
const equalsQuery = (key, value) =>
  `&${qs.stringify(
    {
      filters: {
        [key]: { $eq: value }
      }
    },
    {
      encodeValuesOnly: true
    }
  )}`
