import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import imagesBase64 from 'utils/imagesBase64'

import MenuButton from '../elements/buttons/menuButton'
import CustomImage from '../elements/images/image'
import NavbarLink from '../elements/navbarLink'
import NavbarMobile from './navbarMobile'

const Navbar = ({ navbar }) => {
  const { sections } = navbar
  const [buttonMenuOpened, setButtonMenuOpened] = useState(false)
  const [isSticky, setSticky] = useState(true)
  const [active, setActive] = useState()
  const sliderRef = useRef()
  const className = buttonMenuOpened ? 'navbar menu-opened' : 'navbar'

  const router = useRouter()
  const logoColor = router.asPath === '/' ? 'black' : 'white'

  const toggleShowMenu = useCallback(() => {
    setButtonMenuOpened(prev => !prev)
  }, [])

  const changeSlider = useCallback(
    element => {
      if (!element) sliderRef.current.style.opacity = '0'
      else {
        sliderRef.current.style.opacity = '1'
        sliderRef.current.style.left = `${element.current.offsetLeft}px`
        sliderRef.current.style.width = `${element.current.offsetWidth}px`
      }
    },
    [sliderRef]
  )

  const updateSlider = () => {
    changeSlider(active)
  }

  const changeActive = element => {
    if (element !== active) clickAnimation()
    setActive(element)
  }

  const clickAnimation = () => {
    // Trigger the shrink animation
    if (sliderRef.current) {
      sliderRef.current.style.transform = 'scale(0.9)'
      sliderRef.current.style.transition = 'all 0.1s ease-out'
      setTimeout(() => {
        sliderRef.current.style.transform = 'scale(1)'
        sliderRef.current.style.transition = 'all 0.3s ease-in-out'
      }, 300)
    }
  }

  useEffect(() => {
    changeSlider(active)
  }, [active])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(false)
        setButtonMenuOpened(false)
      } else setSticky(true)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', updateSlider)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', updateSlider)
    }
  }, [])

  return (
    <div
      className={className}
      style={{
        top: isSticky ? '0' : '-100vh'
      }}
    >
      <div className="navbar-container">
        <Link href="/">
          <div className="navbar-logo-container">
            <div className="navbar-logo-image">
              <CustomImage
                alt="logo"
                src={imagesBase64.navbarLogoImage}
                layout="fill"
                objectFit="contain"
              />
            </div>
            <div className="navbar-logo-title" style={{ color: logoColor }}>
              <div>CELTIBERIAN</div>
              <div>SOLUTIONS</div>
            </div>
          </div>
        </Link>
        <div
          className="navbar-sections-wrapper"
          onMouseLeave={() => changeSlider(active)}
        >
          {sections.map(section => (
            <NavbarLink
              key={section.id}
              section={section}
              onMouseEnter={changeSlider}
              changeActive={changeActive}
              onClick={toggleShowMenu}
            />
          ))}
          <div className="navbar-slider" ref={sliderRef} />
        </div>
      </div>
      <NavbarMobile sections={sections} />
    </div>
  )
}

export default memo(Navbar)
