import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo } from 'react'

import SocialMediaButtons from '@/components/elements/buttons/socialMediaButtons'
import CustomImage from '@/components/elements/images/image'

import FooterLink from '../elements/footerLink'

const FooterSectionContainer = ({ footerSection }) => {
  const router = useRouter()
  return (
    <div className="footer-sections-container" key={footerSection.id}>
      {footerSection.links.map(({ id, text, url, icon }) => {
        const linkRoute = `/${text.toLowerCase()}`
        const isActive =
          router.asPath === '/' ? '/home' === linkRoute : router.asPath === linkRoute
        const className = classNames('footer-sections-link-text', {
          'is-active': isActive
        })
        return (
          <FooterLink key={id} text={text} url={url} icon={icon} className={className} />
        )
      })}
    </div>
  )
}

const FooterLegal = ({ legal }) => (
  <div className="footer-legal-container" key={legal.id}>
    {legal.links.map(link => (
      <Link key={link.id} href={link.url}>
        <a target={link.newTab ? '_blank' : '_self'}>{link.text}</a>
      </Link>
    ))}
  </div>
)

const Footer = memo(({ footer }) => {
  const { footerInfo, footerSections, socials, legal } = footer

  return (
    <div id="footer" className="footer">
      <svg
        width="100%"
        viewBox="0 0 960 200"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="none"
      >
        <path
          fill="#7497BF"
          d="m0,34l17.8,12.2c17.9,12.1 53.5,36.5 89,42.8c35.5,6.3 70.9,-5.3 106.4,-19.7c35.5,-14.3 71.1,-31.3 106.8,-27.1c35.7,4.1 71.3,29.5 106.8,30.1c35.5,0.7 70.9,-23.3 106.4,-34.6c35.5,-11.4 71.1,-10 106.8,-8c35.7,2 71.3,4.6 106.8,3c35.5,-1.7 70.9,-7.7 106.4,-6.7c35.5,1 71.1,9 89,13l17.8,4l0,163l-17.8,0c-17.9,0 -53.5,0 -89,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -106.8,0c-35.7,0 -71.3,0 -106.8,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -106.8,0c-35.7,0 -71.3,0 -106.8,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -89,0l-17.8,0l0,-172z"
        />
        <path
          fill="#4272AE"
          d="m0,81l17.8,3.8c17.9,3.9 53.5,11.5 89,13.7c35.5,2.2 70.9,-1.2 106.4,4.3c35.5,5.5 71.1,19.9 106.8,26.4c35.7,6.5 71.3,5.1 106.8,-4.7c35.5,-9.8 70.9,-28.2 106.4,-28.5c35.5,-0.3 71.1,17.3 106.8,21.5c35.7,4.2 71.3,-5.2 106.8,-6c35.5,-0.8 70.9,6.8 106.4,9.7c35.5,2.8 71.1,0.8 89,-0.2l17.8,-1l0,86l-17.8,0c-17.9,0 -53.5,0 -89,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -106.8,0c-35.7,0 -71.3,0 -106.8,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -106.8,0c-35.7,0 -71.3,0 -106.8,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -89,0l-17.8,0l0,-125z"
        />
        <path
          fill="#183e9e"
          d="m0,165l17.8,-7c17.9,-7 53.5,-21 89,-20.7c35.5,0.4 70.9,15 106.4,19c35.5,4 71.1,-2.6 106.8,-0.6c35.7,2 71.3,12.6 106.8,10c35.5,-2.7 70.9,-18.7 106.4,-19.2c35.5,-0.5 71.1,14.5 106.8,19.2c35.7,4.6 71.3,-1 106.8,-8.4c35.5,-7.3 70.9,-16.3 106.4,-21.5c35.5,-5.1 71.1,-6.5 89,-7.1l17.8,-0.7l0,78l-17.8,0c-17.9,0 -53.5,0 -89,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -106.8,0c-35.7,0 -71.3,0 -106.8,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -106.8,0c-35.7,0 -71.3,0 -106.8,0c-35.5,0 -70.9,0 -106.4,0c-35.5,0 -71.1,0 -89,0l-17.8,0l0,-41z"
        />
      </svg>
      <div className="footer-info-container">
        <div className="footer-address-container">
          <div className="footer-address-image">
            <CustomImage
              media={footerInfo.logo.data.attributes}
              layout="fill"
              objectFit="contain"
              objectPosition="left"
            />
          </div>
          <div className="footer-address-details">
            <p className="footer-address-text-style">{footerInfo.address}</p>
          </div>
          <FooterLegal legal={legal} />
          <SocialMediaButtons socialMediaList={socials.social} />
        </div>
        <FooterSectionContainer footerSection={footerSections} />
      </div>
    </div>
  )
})
Footer.displayName = 'Footer'

export default Footer
