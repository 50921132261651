import React, { memo } from 'react'

import CustomImage from '@/components/elements/images/image'

const SocialMediaButtons = ({ socialMediaList }) => {
  return (
    <div className="social-media-buttons-container">
      {socialMediaList.map(social => (
        <div className="social-media-buttons-images" key={social.id}>
          <a href={social.url} aria-label="Check us in social media">
            <CustomImage
              media={social.image.data.attributes}
              layout="fill"
              objectPosition="center"
              objectFit="cover"
            ></CustomImage>
          </a>
        </div>
      ))}
    </div>
  )
}

export default memo(SocialMediaButtons)
