import { memo, useEffect, useState } from 'react'
import NavbarLink from '../elements/navbarLink'
import { EllipsisOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

const NavbarMobile = ({ sections, sectionsToShow = 6 }) => {
  const [isSticky, setSticky] = useState(true)
  const [open, setOpen] = useState(false)

  const shownLinks = sections
    .slice(0, sectionsToShow)
    .map(section => <NavbarLink key={section.id} section={section} />)
  const hiddenLinks = sections
    .slice(sectionsToShow)
    .map(section => (
      <NavbarLink key={section.id} section={section} className="navbar-popover-link" />
    ))

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(false)
        setOpen(false)
      } else setSticky(true)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleOpenChange = () => {
    setOpen(!open)
  }

  return (
    <div
      className={`navbar-mobile`}
      style={{
        bottom: isSticky ? '5px' : '-15vh'
      }}
    >
      {shownLinks}
      {hiddenLinks.length > 0 && (
        <Popover
          content={hiddenLinks}
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
          className="navbar-link-text"
        >
          <EllipsisOutlined className="navbar-ellipsis" onClick={handleOpenChange} />
          <div className="navbar-link-text">More</div>
        </Popover>
      )}
    </div>
  )
}

export default memo(NavbarMobile)
