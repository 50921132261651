import isEmpty from 'lodash/isEmpty'
import Link from 'next/link'

import CustomImage from './images/image'

const FooterLink = ({ text, icon, url, className = '' }) => {
  return (
    <Link href={url}>
      <div className={className}>
        {!isEmpty(icon?.data) && (
          <CustomImage
            media={icon.data.attributes}
            width={25}
            height={25}
            alt={text}
            priority
            className="navbar-link-icon"
            layout="fixed"
          />
        )}
        <p>{text}</p>
      </div>
    </Link>
  )
}

export default FooterLink
